import React from 'react'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Button,
  Heading,
  Text,
  Image,
  Hr,
  Grid,
} from 'components'

const IconTitleDescriptionList = ({ items = [], belowListText }) => {
  return (
    <Box sx={{ maxWidth: '800px' }} mx="auto">
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 'large',
          border: '1px solid',
          borderColor: 'colorBlue10',
          mx: 'auto',
        }}
      >
        {items.map(({ icon, title, description }, index) => (
          <>
            <Box
              sx={{
                px: 6,
                py: [5, 4],
                mx: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['auto', '120px auto'],
                  alignItems: 'center',
                  justifyItems: ['center', 'start'],
                  textAlign: ['center', 'left'],
                }}
              >
                <Image
                  src={icon}
                  alt={title}
                  loading="lazy"
                  role="presentation"
                  height="128px"
                  mb={2}
                />
                <Box>
                  <Text
                    as="h3"
                    mb={2}
                    color="colorBlue90"
                    fontWeight="display"
                    fontSize={4}
                    color="colorBlue90"
                  >
                    {title}
                  </Text>
                  <Text fontSize={2} color="colorBlue100">
                    {description}
                  </Text>
                </Box>
              </Box>
            </Box>
            {index + 1 < items.length && <Hr my={0} width="auto" />}
          </>
        ))}
      </Box>
      {belowListText && <Text mt={5}>{belowListText}</Text>}
      <Text></Text>
    </Box>
  )
}

export default IconTitleDescriptionList
