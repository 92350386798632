import React from 'react'

import {
  Section,
  Container,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Grid,
} from 'components'

import IconTitleDescriptionList from 'screens/Home/components/IconTitleDescriptionList'
import BulletPointsList from 'screens/Home/components/BulletPointsList'

const defaultValues = [
  {
    icon: '/icons/bambus-sustainability.svg',
    title: 'Nachhaltig Vermögen sichern',
    description:
      'Immobilienvermögen nutzbar machen z.B. zum Werterhalt (sanieren, renovieren)',
  },
  {
    icon: '/icons/bambus-transparency.svg',
    title: 'Transparente Prozesse',
    description:
      'Jeder Schritt ist nachvollziehbar und der aktuelle Status einsehbar',
  },
  {
    icon: '/icons/bambus-clarity.svg',
    title: 'Einfach übersichtlich',
    description:
      'Von der Antragsstrecke bis zum Finanz-Dashboard in Ihrem persönlichen Kundenbereich',
  },
]

const ValuesAndSolutions = ({
  title = 'Auf Bambus können Sie bauen',
  subheading = 'Wir verschaffen finanzielle Flexibilität – einfach, sicher & transparent',
  values = defaultValues,
  solutions = [],
  belowListText = '',
  belowSolutionsText = '',
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Section.Header>
            <Heading.H2 textAlign="center" color="colorBlue90">
              {title}
            </Heading.H2>
            {subheading && (
              <Text textAlign="center" fontSize={3} mt={3} color="colorBlue90">
                {subheading}
              </Text>
            )}
          </Section.Header>
        </Section.Header>

        <Section.Body>
          <IconTitleDescriptionList
            items={values}
            belowListText={belowListText}
          />
          <Box sx={{ height: '75px' }} />
          <BulletPointsList
            title="Wir ermöglichen Teilverkauf "
            items={solutions}
            belowListText={belowSolutionsText}
          />
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}></Section.Footer>
      </Container>
    </Section>
  )
}

export default ValuesAndSolutions
