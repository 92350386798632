import React from 'react'
import CheckIcon from 'icons/Check'
import theme from 'theme'

import {
  Section,
  Container,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Grid,
} from 'components'

const BulletPointsList = ({
  title,
  items = [],
  Icon = CheckIcon,
  iconColor = theme.colors.green,
  belowListText,
}) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'colorBlue10',
          padding: [3],
          borderTopLeftRadius: 'large',
          borderTopRightRadius: 'large',
        }}
      >
        <Text
          sx={{
            textAlign: 'center',
            color: 'colorBlue100',
            fontSize: 3,
            fontWeight: 'bold',
          }}
        >
          {title}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr'],
          gap: ['16px', null, '10px'],
          backgroundColor: 'white',
          padding: [4, null, 6],
          borderBottomLeftRadius: 'large',
          borderBottomRightRadius: 'large',
          border: '1px solid',
          borderColor: 'colorBlue10',
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
          {items.slice(0, Math.round(items.length / 2)).map((item) => (
            <Box
              key={item}
              sx={{
                display: 'flex',
                alignItems: 'center',
                svg: {
                  flexShrink: '0',
                },
              }}
            >
              <Icon color={iconColor} />
              <Text sx={{ marginLeft: '18px' }}>{item}</Text>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
          {items.slice(Math.round(items.length / 2)).map((item) => (
            <Box
              key={item}
              sx={{
                display: 'flex',
                alignItems: 'center',
                svg: {
                  flexShrink: '0',
                },
              }}
            >
              <Icon color={iconColor} />
              <Text sx={{ marginLeft: '18px' }}>{item}</Text>
            </Box>
          ))}
        </Box>
      </Box>
      {belowListText && (
        <Text sx={{ maxWidth: '800px' }} mx="auto" mt={5}>
          {belowListText}
        </Text>
      )}
    </Box>
  )
}

export default BulletPointsList
